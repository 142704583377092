import { Elm } from "../Main.elm";

let logo_src = new URL(
  // "../../assets/img/case-smart-ai-thought-bubble-sharp.png",
  "../../assets/img/case-smart-ai-brain-bubble.png",
  import.meta.url,
).href;

Elm.Main.init({
  flags: {
    login_target_url: process.env.DEPO_LOGIN_TARGET_URL,
    depo_brief_url: process.env.DEPO_BRIEF_URL,
    depo_hub_url: process.env.DEPO_HUB_URL,
    depo_scriptly_url: process.env.DEPO_SCRIPTLY_URL,
    logo_src,
  },
});
